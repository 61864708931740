import gql from "graphql-tag";

export const GET_ALL_COMPETITORS_RATE = gql`
  query getAllCompetitorRates {
    getAllCompatitorRates {
      occupency
      checkinDate
      checkoutDate
      competitor
      rate
      platform
    }
  }
`;

export const GET_COMPETITORS_BY_FILTER = gql`
  query getCompetitorsByFilters($filter: filter) {
    getCompetitorsByFilters(filter: $filter) {
      data {
        hostel
        name
        roomName
        prices
        avgPrices
        competitors {
          competitor
          hostelId
          roomName
          prices
        }
      }
      count
    }
  }
`;

//===>
// {
//   "filter": {
//     "checkinDate" : "2024-07-18",
//     "platform": ["Booking.com"],
//     "competitor" : ["The Hosteller"],
//     "occupency" : 1,
//     "limit": 5,
//     "page": 1
//   }
// }

//GetHourlyReportOTA
export const GET_HOURLY_REPORT_OTA = gql`
  query GetHourlyReportOTA(
    $fromDate: String!
    $toDate: String!
    $source: [String]
  ) {
    get_hourly_report_ota(fromDate: $fromDate, toDate: $toDate, source: $source)
  }
`;
